.static-text-dialog {
    font-size: 2rem;

    .modal-dialog {
        max-width: 90%;

        .modal-content {

            .static-text-modal-title {
                font-size: 3rem;
                text-align: center;

                &.size25 {
                    font-size: 2.5rem;
                }
            }

            .modal-body {
                display: flex;
                flex-direction: column;

                textarea {
                    flex-grow: 1;
                    margin: 5px;
                    outline: 1px solid black;
                    padding: 1rem;
                }
            }

            .btn {
                font-size: 2rem;
                line-height: 2;
            }
        }
    }

    
}