.sb-initial-count {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    height: 100vh;
    font-size: 2rem;
    overflow-y: auto;

    .row {
        margin-top: 1.5rem;

        &.noTopMargin {
            margin-top: 0;
        }
    }

    .restOfPage {
        flex-grow: 1;
        justify-content: end;
        display: flex;
        flex-direction: column;

        .large-button {
            margin: 0 auto;
            line-height: 1.5;
        }
    }
}

