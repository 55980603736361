.sb-landing {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    height: 100vh;
    font-size: 2rem;

    .row {
        margin-top: 1.5rem;
    }

    .restOfPage {
        flex-grow: 1;
        justify-content: end;
        display: flex;
        flex-direction: column;

        .large-button {
            margin: 0 auto;
        }
    }
}

.code-verif .modal-header .btn-close {
    display:none;
}

.invalid-code .ok-button {
    width: 50%;
}
