.header {
    .header-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3, p, em {
            margin-bottom: 0.5rem;
            font-size: 2rem;
        }
    }
}
.title {
    margin-top: 3rem;
    h1 {
        font-size: 2.5rem;
        font-weight: normal;
    }
}