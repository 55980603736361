$font-size: 1.8rem;

.ev-count-verification, .ev-initial-count {
    padding: 3rem;
    font-size: $font-size;

    .btn {
        font-size: $font-size;
        padding: 0.5rem 4rem;
        margin: 1.5rem;

        &.btn-link {
            padding: 0.5rem;
        }
    }

    .row {
        margin-bottom: 1.5rem;
    }

    .section-header {
        margin-top: 1.5rem;
        font-weight: normal;
        text-decoration: underline;
        font-size: 2rem;
    }

    input {
        outline: 1px solid black;
        border: none;
        font-size: $font-size;
        padding: 0 0.75rem;
        text-align: right;
    }
}