.app-content {
  text-align: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: black;
  display: flex;

  .button-wrap {
    width: 100%;
  }

  .large-button {
    width: 75%;
    font-size: 3rem;
    margin-bottom: 6rem;
    line-height: 2.5;
  }
}

.background {
  z-index: -1;
  background-image: url(./images/Cook-County-DarkLightBlue-v2.png);
  opacity: 0.06;
  position: fixed;
  left: -50vw;
  top: -50vh;
  width: 200vw;
  height: 200vh;
  overflow: visible;
  background-size: max(140vw, 140vh);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transform: rotate(-30deg);
}

.App-logo {
  height: min(20vh, 20vw);
}

.App-link {
  color: #61dafb;
}

.modal-body {
  padding: 3rem;
}

.modal-footer {
  justify-content: center;

  .spread:first-child {
    width: 31%;
  }

  .spread:nth-child(2) {
      width: 66%;
      margin-left: auto;
  }
}