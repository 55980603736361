.ev-select-location {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    height: 100vh;
    font-size: 2rem;

    .row {
        margin-top: 1.5rem;
    }

    .ev-select-location-input {
        outline: 1px solid black;
        width: 80%;
    }

    .restOfPage {
        flex-grow: 1;
        justify-content: end;
        display: flex;
        flex-direction: column;

        .large-button {
            margin: 0 auto;
        }
    }
}

