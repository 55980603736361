.reporting{
    padding: 3rem;
    .first-button{
        margin-top: 3em;
    }
    .large-button {
        font-size: 1.5em;

        // these gradients are taken from https://codepen.io/bork/pen/WNrmWr
        &.morning {
            color: black;
            background: #efeebc;
            background: -moz-linear-gradient(top,  #efeebc 0%, #61d0cf 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#efeebc), color-stop(100%,#61d0cf));
            background: -webkit-linear-gradient(top,  #efeebc 0%,#61d0cf 100%);
            background: -o-linear-gradient(top,  #efeebc 0%,#61d0cf 100%);
            background: -ms-linear-gradient(top,  #efeebc 0%,#61d0cf 100%);
            background: linear-gradient(to bottom,  #efeebc 0%,#61d0cf 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efeebc', endColorstr='#61d0cf',GradientType=0 );
        }

        &.night {
            background: #5b2c83;
            background: -moz-linear-gradient(top,  #5b2c83 0%, #d1628b 100%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#5b2c83), color-stop(100%,#d1628b));
            background: -webkit-linear-gradient(top,  #5b2c83 0%,#d1628b 100%);
            background: -o-linear-gradient(top,  #5b2c83 0%,#d1628b 100%);
            background: -ms-linear-gradient(top,  #5b2c83 0%,#d1628b 100%);
            background: linear-gradient(to bottom,  #5b2c83 0%,#d1628b 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b2c83', endColorstr='#d1628b',GradientType=0 );
        }
    }
}