.info-modal {
    font-size: 2rem;

    .modal-dialog {
        max-width: 90%;

        .modal-content {
            height: 60vh;

            #modal-title {
                font-size: 3rem;
                text-align: center;
            }

            .modal-body {
                display: flex;
                flex-direction: column;

                textarea {
                    flex-grow: 1;
                    margin: 5px;
                    outline: 1px solid black;
                    padding: 1rem;
                    font-size: 2rem;
                }
            }

            .btn {
                font-size: 2rem;
                line-height: 2;
            }
        }
    }
}